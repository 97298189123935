import { useDispatch } from "react-redux";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { getOverallPNL } from "../../utils/positions";
import OrderCategory from "./OrderCategory";
import { useNavigate } from "react-router-dom";
import Commands from "./Modals/Commands";
import DeleteModal from "./Modals/DeleteModal";
import ChangePositionModal from "./Modals/ChangePositionModal";
import {
  groupAssetTransfer,
  getComments,
  updateGroup,
  readComments,
  getOrderbook,
  cancelAllOrder,
} from "../../services/admin.service";
import { openToast } from "../../redux/slice/toastSlice";
import { userData } from "../../services/auth.service";
import { FaRegCommentDots } from "react-icons/fa6";
import { AiOutlineDelete } from "react-icons/ai";
import { FaEdit } from "react-icons/fa";
import * as style from "../../pages/client/style";
import { FaCheck, FaTimes } from "react-icons/fa";
import { Spinner } from "react-bootstrap";
import isEmpty from "is-empty";

const PositionClientContainer = ({
  collapse,
  setCollapse,
  client,
  toggleOpen,
  open,
  handleRefreshClient,
  index,
  handleShowSquareOff,
  handleShowStopLoss,
  handleAllSquareOff,
  handleOpenPlaceOrder,
  pos,
  posIndex,
  handleDeleteGroup,
  isModalOpen,
  openModal,
  closeModal,
  fetchPositionsData,
}) => {
  const navigate = useNavigate();
  const [position, setPosition] = useState(pos);
  const [showCommandsModal, setShowCommandsModal] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const dispatch = useDispatch();
  const [commentCount, setCommentCount] = useState({});
  const [comments, setComments] = useState([]);
  const [showChangePositionModal, setShowChangePositionModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [toGroup, setToGroup] = useState("");
  const [selectedContracts, setSelectedContracts] = useState({});
  const [currentUser, setCurrentUser] = useState([]);
  const [hasUnreadComments, setHasUnreadComments] = useState({});
  const [editingGroupId, setEditingGroupId] = useState(null);
  const [groupName, setGroupName] = useState("");
  const [loader, setLoader] = useState(false);

  const handleChangeInOrders = useCallback(
    (orKey, updatedOrders, isMounted) => {
      setPosition((prev) => {
        let copy = { ...prev };
        copy[orKey] = updatedOrders;
        return copy;
      });
    },
    []
  );

  const quantityValidate = (positions) => {
    let isValid = false;
    positions.forEach((position) => {
      if (parseInt(position.quantity) > 0) {
        isValid = true;
      }
    });
    return isValid;
  };

  const handleOpenChangePosition = (group) => {
    setSelectedGroup(group);
    setShowChangePositionModal(true);
  };

  const handleEditGroup = (group) => {
    setEditingGroupId(group.group_id);
    setGroupName(group.group_name);
  };

  const handleSaveGroup = async (group) => {
    try {
      if (groupName !== "") {
        const response = await updateGroup(group, {
          group_name: groupName,
        });
        if (response?.success) {
          fetchPositionsData();
          setEditingGroupId(null);
        } else {
          setEditingGroupId(null);
          dispatch(
            openToast({
              message: response.message,
              type: "error",
            })
          );
        }
      }
    } catch (error) {
      console.error("Error:", error);
      dispatch(
        openToast({
          message: "Something went wrong",
          type: "error",
        })
      );
    }
  };

  const handleCancelEdit = () => {
    setEditingGroupId(null);
    setGroupName("");
  };

  const handleCloseChangePosition = () => {
    resetForm();
    setShowChangePositionModal(false);
    setSelectedGroup(null);
  };

  const resetForm = () => {
    setToGroup("");
    setSelectedContracts({});
  };

  const handleTransfer = async (fromGroup, toGroup, contracts) => {
    const formattedContracts = contracts.map((contractObj) => ({
      from: fromGroup,
      to: toGroup,
      contract: contractObj.contract,
      quantity: parseInt(contractObj.quantity, 10),
    }));

    try {
      for (const contract of formattedContracts) {
        const response = await groupAssetTransfer(contract);

        if (response?.success) {
          fetchPositionsData();
          handleCloseModal();
          resetForm();
        } else {
          dispatch(
            openToast({
              message: response.message,
              type: "error",
            })
          );
          break;
        }
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  const handleCreateCategory = (client, pos) => {
    navigate(`/group/${client._id}`, { state: { client } });
  };

  const handleCancelAllOrders = async (client, pos) => {
    if (!client?._id || !pos?.broker) {
      dispatch(
        openToast({ message: "Invalid client or broker data", type: "error" })
      );
      return;
    }

    setLoader(true);
    try {
      const { success, message, result } = await getOrderbook(
        client._id,
        pos.broker
      );

      if (!success) {
        setLoader(false);
        dispatch(openToast({ message, type: "error" }));
        return;
      }

      const allOrderIds = (result?.orders || [])
        .flatMap((brokerage) => brokerage.orders)
        .filter((order) =>
          [
            "pending",
            "open",
            "open pending",
            "open-pending",
            "requested",
            "r",
            "queued",
            "q",
            "ordered",
            "o",
            "Partially Executed",
            "p",
            "trigger pending",
          ].includes(order.status.toLowerCase())
        )
        .map((order) => order.order_id);

      if (isEmpty(allOrderIds)) {
        setLoader(false);
        dispatch(
          openToast({ message: "No cancellable orders found", type: "error" })
        );
        return;
      }

      const payload = {
        user_id: client._id,
        order_ids: allOrderIds,
      };

      const cancelResponse = await cancelAllOrder(payload);

      if (cancelResponse?.success) {
        setLoader(false);
      } else {
        setLoader(false);
        dispatch(
          openToast({
            message: cancelResponse.message || "Failed to cancel orders",
            type: "error",
          })
        );
      }
    } catch (error) {
      setLoader(false);
      console.error("Error canceling all orders:", error);
      dispatch(
        openToast({
          message: "An error occurred while canceling orders",
          type: "error",
        })
      );
    }
  };

  const fetchComments = async () => {
    // setLoading(true);
    try {
      const response = await getComments(selectedGroupId);
      if (response.success) {
        setComments(response.data);
      } else {
        dispatch(
          openToast({
            message: response.message,
            type: "error",
          })
        );
      }
    } catch (error) {
      dispatch(
        openToast({
          message: "Something went wrong",
          type: "error",
        })
      );
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const { user } = await userData();
      setCurrentUser(user);
    };

    fetchData();
  }, []);

  const fetchInitialCommentCounts = async () => {
    if (!currentUser) return;

    const unreadStatus = {};

    if (position?.groups) {
      await Promise.all(
        position.groups.map(async (group) => {
          try {
            const response = await getComments(group.group_id);
            if (response?.success) {
              const unreadComments = response.data.some(
                (comment) =>
                  !comment.read_by.some((read) => read.$oid === currentUser._id)
              );
              unreadStatus[group.group_id] = unreadComments;
            } else {
              unreadStatus[group.group_id] = false;
            }
          } catch (error) {
            unreadStatus[group.group_id] = false;
          }
        })
      );
    }
    setHasUnreadComments(unreadStatus);
  };

  useEffect(() => {
    fetchInitialCommentCounts();
  }, [position?.groups, currentUser]);

  const getGroupName = (groupId) => {
    return pos?.groups?.find((g) => g?.group_id === groupId)?.group_name;
  };

  const handleCommands = async (group_id) => {
    setSelectedGroupId(group_id);
    setShowCommandsModal(true);

    try {
      const response = await readComments(currentUser?._id, group_id);
      if (response?.success) {
        setHasUnreadComments((prev) => ({ ...prev, [group_id]: false }));
      }
    } catch (error) {
      console.error("Failed to mark comments as read:", error);
    }
    // setViewedComments((prev) => ({ ...prev, [group_id]: true }));
    // setCommentCount((prev) => ({ ...prev, [group_id]: 0 }));
  };

  const handleCommentChange = (groupId, count) => {
    // if (!viewedComments[groupId]) {
    //   setCommentCount((prev) => ({ ...prev, [groupId]: count }));
    // }
    setCommentCount((prev) => ({ ...prev, [groupId]: count }));
  };

  const handleCloseModal = () => {
    setShowCommandsModal(false);
    setSelectedGroupId(null);
  };

  return (
    <Fragment>
      <table className="margin-table">
        <tr className="mb-2 margin-tr">
          <table className="margin-table box supreme-blue font-normal">
            <thead className="profit-and-loss-bg margin-thead table-head">
              <tr className="profit-and-loss-bg margin-table">
                <th className="profit-and-loss-bg margin-th">
                  <button
                    className="rounded-full w-8 h-8 button-bg-blue text-white"
                    onClick={() =>
                      toggleOpen(`client-${client._id}-${pos.broker}`)
                    }
                  >
                    <span
                      className={`bi ${
                        (!open.includes(`client-${client._id}-${pos.broker}`) &&
                          (collapse == "none" || collapse == "strategy")) ||
                        (collapse == "client" &&
                          open.includes(`client-${client._id}-${pos.broker}`))
                          ? "bi-dash"
                          : "bi-plus"
                      } p-0`}
                    />
                  </button>
                  {position.status && (
                    <label className="all-normal-text">
                      &nbsp;&nbsp;P&L:&nbsp;&nbsp;
                    </label>
                  )}
                  {position.status ? (
                    <label
                      className={`text-status all-normal-text font-semibold ${
                        getOverallPNL(position.groups).pnl < 0
                          ? "loss-color"
                          : "profit-color"
                      }`}
                    >
                      {getOverallPNL(position.groups).prefix}
                      {getOverallPNL(position.groups).pnl.toLocaleString(
                        "en-IN",
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )}
                    </label>
                  ) : (
                    <label className="text-status text-dark">{""}</label>
                  )}

                  {/* <button
                    className="rounded-full w-8 h-8 button-bg-blue text-white float-end ml-2"
                    onClick={() =>
                      handleRefreshClient(
                        client._id,
                        position.broker,
                        index,
                        posIndex
                      )
                    }
                    disabled={loader.includes(
                      `loading-${client._id}-${position.broker}-${index}`
                    )}
                  >
                    {loader.includes(
                      `loading-${client._id}-${position.broker}-${index}`
                    ) ? (
                      <Spinner size="sm" />
                    ) : (
                      <span className="bi bi-arrow-clockwise" />
                    )}
                  </button> */}
                  <label className="float-end font-semibold mt-1">
                    {client.name} (
                    {position.broker.toUpperCase() === "ICICI"
                      ? "ICICI Direct"
                      : position.broker.charAt(0).toUpperCase() +
                        position.broker.slice(1)}{" "}
                    - {position.capital}){" "}
                    {!position.status && (
                      <Fragment>
                        <span className="loss-color">
                          {" "}
                          {position.message === "API key or token error"
                            ? "(Login error)"
                            : " "}
                        </span>
                      </Fragment>
                    )}
                  </label>
                </th>
              </tr>
            </thead>
          </table>
          {position?.groups?.map((group) => (
            <Fragment key={group.group_id}>
              {((!open.includes(`client-${client._id}-${pos.broker}`) &&
                (collapse == "none" || collapse == "strategy")) ||
                (collapse == "client" &&
                  open.includes(`client-${client._id}-${pos.broker}`))) && (
                <table className="margin-table mb-3">
                  <thead className="margin-thead table-head">
                    <tr className="margin-tr">
                      <th colSpan={14} className="p-0 margin-th">
                        <div className="ps-button-label-wrapper p-2 flex items-center justify-between w-full">
                          <div className="flex items-center">
                            <button
                              className="rounded-full w-8 h-8 button-bg-blue text-white"
                              onClick={() =>
                                toggleOpen(
                                  `client-${client._id}-${pos.broker}-${group.group_id}`
                                )
                              }
                            >
                              <span
                                className={`bi ${
                                  (!open.includes(
                                    `client-${client._id}-${pos.broker}-${group.group_id}`
                                  ) &&
                                    (collapse == "none" ||
                                      collapse == "client")) ||
                                  (collapse == "strategy" &&
                                    open.includes(
                                      `client-${client._id}-${pos.broker}-${group.group_id}`
                                    ))
                                    ? "bi-dash"
                                    : "bi-plus"
                                } p-0`}
                              />
                            </button>
                            {editingGroupId === group.group_id ? (
                              <input
                                type="text"
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                                className="ml-2 pl-2 h-6"
                              />
                            ) : (
                              <label className="ml-2">
                                &nbsp;{group.group_name}
                              </label>
                            )}
                            {editingGroupId === group.group_id ? (
                              <div className="flex gap-2">
                                <div
                                  className="ml-2 cursor-pointer icon-hover"
                                  // className="items-center h-8 px-2 text-white mr-2 text-base border-none float-end cursor-pointer focus:outline-none button-style"
                                  onClick={() =>
                                    handleSaveGroup(group.group_id)
                                  }
                                >
                                  {/* Save */}
                                  <FaCheck fontSize={"large"} />
                                </div>
                                <div
                                  className="cursor-pointer icon-hover"
                                  // className="items-center h-8 px-2 text-white mr-2 text-base border-none float-end cursor-pointer focus:outline-none button-style"
                                  onClick={handleCancelEdit}
                                >
                                  {/* Cancel */}
                                  <FaTimes fontSize={"large"} />
                                </div>
                              </div>
                            ) : (
                              group?.group_name !== "Default" && (
                                <button
                                  className="icon-hover"
                                  // className="items-center h-8 px-2 text-white mr-2 text-base border-none float-end cursor-pointer focus:outline-none button-style"
                                  onClick={() => handleEditGroup(group)}
                                >
                                  {/* Edit */}
                                  <style.PenIcon />
                                </button>
                              )
                            )}
                          </div>
                          <div className="flex gap-2 items-center">
                            <div
                              key={group.group_id}
                              onClick={() => handleCommands(group.group_id)}
                              className="flex cursor-pointer icon-hover"
                              style={{ paddingRight: "4px" }}
                              // className={`items-center h-8 px-2 text-white mr-2 text-base border-none float-end cursor-pointer focus:outline-none button-style`}
                            >
                              <FaRegCommentDots
                                fontSize={"large"}
                                style={{ fontSize: "22px" }}
                              />
                              {/* Comments */}
                              {hasUnreadComments[group.group_id] && (
                                <span className="ml-0.5">*</span>
                              )}
                            </div>
                            <div
                              className="cursor-pointer icon-hover"
                              // className="items-center h-8 px-2 text-white mr-2 text-base border-none float-end cursor-pointer focus:outline-none button-style"
                              onClick={() => handleOpenChangePosition(group)}
                            >
                              {/* Modify */}
                              <FaEdit
                                fontSize={"large"}
                                style={{ fontSize: "22px" }}
                              />
                            </div>
                            {group?.group_name !== "Default" && (
                              <div
                                onClick={() => openModal(group.group_id)}
                                className="cursor-pointer icon-hover"
                                // className="items-center h-8 px-2 text-white bg-gray-900 mr-2 text-base border-none float-end cursor-pointer focus:outline-none button-style"
                              >
                                <AiOutlineDelete
                                  fontSize={"large"}
                                  style={{ fontSize: "22px" }}
                                />
                                {/* Delete */}
                              </div>
                            )}
                          </div>
                        </div>
                      </th>
                    </tr>
                    {((!open.includes(
                      `client-${client._id}-${pos.broker}-${group.group_id}`
                    ) &&
                      (collapse == "none" || collapse == "client")) ||
                      (collapse == "strategy" &&
                        open.includes(
                          `client-${client._id}-${pos.broker}-${group.group_id}`
                        ))) && (
                      <tr className="order-headers-bg all-normal-text">
                        <th
                          className="all-normal-text table-head margin-th"
                          style={{ width: "24%" }}
                        >
                          Contract
                        </th>
                        <th
                          align="center"
                          className="all-normal-text table-head margin-th"
                          style={{ width: "4%", fontSize: "10pt" }}
                        >
                          Position
                        </th>
                        <th
                          className="text-center all-normal-text table-head margin-th"
                          align="center"
                          style={{ width: "4%", fontSize: "10pt" }}
                        >
                          Qty
                        </th>
                        <th
                          className="text-center all-normal-text table-head margin-th"
                          align="center"
                          style={{ width: "4%", fontSize: "10pt" }}
                        >
                          Buy&nbsp;Qty
                        </th>
                        <th
                          className="text-center all-normal-text table-head margin-th"
                          align="center"
                          style={{ width: "4%", fontSize: "10pt" }}
                        >
                          Sell&nbsp;Qty
                        </th>
                        <th
                          className="text-center all-normal-text table-head margin-th"
                          align="center"
                          style={{ width: "5.6%", fontSize: "10pt" }}
                        >
                          Avg&nbsp;Price
                        </th>
                        <th
                          className="text-center all-normal-text table-head margin-th"
                          align="center"
                          style={{ width: "5.6%", fontSize: "10pt" }}
                        >
                          LTP
                        </th>
                        <th
                          align="center"
                          className="text-center all-normal-text table-head margin-th"
                          style={{ width: "5.6%", fontSize: "10pt" }}
                        >
                          Delta
                        </th>
                        <th
                          className="text-center all-normal-text table-head margin-th"
                          align="center"
                          style={{ width: "5.6%", fontSize: "10pt" }}
                        >
                          % P&L
                        </th>
                        <th
                          className="text-center all-normal-text table-head margin-th"
                          align="center"
                          style={{ width: "5.6%" }}
                        >
                          P&L
                        </th>
                        <th
                          className="text-center all-normal-text table-head margin-th"
                          style={{ width: "8%", fontSize: "10pt" }}
                        >
                          Action
                        </th>
                        <th
                          className="text-center all-normal-text table-head margin-th"
                          style={{ width: "8%", fontSize: "10pt" }}
                        >
                          Action
                        </th>
                        <th
                          className="text-center all-normal-text table-head margin-th"
                          style={{ width: "8%", fontSize: "10pt" }}
                        >
                          Action
                        </th>
                        <th
                          className="text-center all-normal-text table-head margin-th"
                          style={{ width: "8%", fontSize: "10pt" }}
                        >
                          Action
                        </th>
                      </tr>
                    )}
                  </thead>
                  <OrderCategory
                    collapse={collapse}
                    orders={group.positions}
                    open={open}
                    client={client}
                    orderKey={group.group_id}
                    pos={pos}
                    handleShowSquareOff={handleShowSquareOff}
                    handleShowStopLoss={handleShowStopLoss}
                    group={group?.group_id}
                    handleAllSquareOff={handleAllSquareOff}
                    handleChangeInOrders={handleChangeInOrders}
                    getGroupName={getGroupName}
                  />
                </table>
              )}
              <DeleteModal
                handleDeleteGroup={handleDeleteGroup}
                openModal={openModal}
                closeModal={closeModal}
                isModalOpen={isModalOpen}
                groupId={group?.group_id}
              />
            </Fragment>
          ))}
        </tr>
        <div>
          {position?.message !== "API key or token error" && (
            <table className="table">
              <thead className="table-border-black margin-th">
                <button
                  disabled
                  className="float-end ps-controls-btn disabled all-normal-text"
                >
                  Square Off All Positions
                </button>
                <button
                  onClick={() => handleCreateCategory(client, pos)}
                  className="float-end ps-controls-btn disabled all-normal-text"
                >
                  Create Strategy
                </button>
                <button
                  onClick={() => handleCancelAllOrders(client, pos)}
                  className="float-end ps-controls-btn disabled all-normal-text"
                >
                  {loader ? <Spinner size="sm" /> : "Cancel all orders"}
                </button>
                <button
                  className="float-end ps-controls-btn all-normal-text"
                  onClick={() =>
                    navigate(
                      `/order-book/${client._id}?broker=${position.broker}`
                    )
                  }
                >
                  Order Book
                </button>
                {/* <button
                  disabled
                  className="float-end ps-controls-btn disabled all-normal-text"
                >
                  Syn Future
                </button> */}
                <button
                  className="float-end ps-controls-btn all-normal-text"
                  onClick={() => handleOpenPlaceOrder(client, position.broker)}
                >
                  Place Order
                </button>
              </thead>
            </table>
          )}
        </div>
      </table>
      <Commands
        show={showCommandsModal}
        onHide={handleCloseModal}
        groupId={selectedGroupId}
        client={client}
        onCommentChange={(count) => handleCommentChange(selectedGroupId, count)}
        comments={comments}
        fetchComments={fetchComments}
      />
      <ChangePositionModal
        show={showChangePositionModal}
        onHide={handleCloseChangePosition}
        fromGroup={selectedGroup?.group_id}
        availableGroups={
          position?.groups?.filter(
            (grp) => grp.group_id !== selectedGroup?.group_id
          ) || []
        }
        positions={selectedGroup?.positions || []}
        onTransfer={handleTransfer}
        getGroupName={getGroupName}
        setToGroup={setToGroup}
        toGroup={toGroup}
        setSelectedContracts={setSelectedContracts}
        selectedContracts={selectedContracts}
      />
    </Fragment>
  );
};

export default PositionClientContainer;
