import React, { useState } from "react";
import { Button, Col, Container, Form, Row, Spinner } from "react-bootstrap";
import DraggableModal from "../../draggableModal/DraggableModal";
import { postStopLoss } from "../../../services/admin.service";
import { useDispatch } from "react-redux";
import { openToast } from "../../../redux/slice/toastSlice";
import moment from "moment";
import isEmpty from "is-empty";

const StopLoss = ({
  show,
  onHide,
  user,
  order,
  brokerage,
  fetchPositionsData,
}) => {
  const [submitLoader, setSubmitLoader] = useState(false);
  const [triggerPrice, setTriggerPrice] = useState("");
  const [limitPrice, setLimitPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const dispatch = useDispatch();

  const ltp = parseFloat(order?.ltp);
  const isBuyPosition = order?.action === "Buy";

  const handleTriggerBlur = () => {
    const trigger = parseFloat(triggerPrice);
    if (isBuyPosition && trigger >= ltp) {
      setTriggerPrice(ltp.toFixed(2));
    } else if (!isBuyPosition && trigger <= ltp) {
      setTriggerPrice(ltp.toFixed(2));
    }
  };

  const handleLimitBlur = () => {
    const trigger = parseFloat(triggerPrice);
    const limit = parseFloat(limitPrice);
    if (isBuyPosition && limit > trigger) {
      setLimitPrice(trigger.toFixed(2) + 1);
    } else if (!isBuyPosition && limit < trigger) {
      setLimitPrice(trigger.toFixed(2));
    }
  };

  let pricePrecentage;

  if (isBuyPosition) {
    pricePrecentage =
      !isEmpty(triggerPrice) &&
      ((triggerPrice - order?.average_price) / order?.average_price) * 100;
  } else {
    pricePrecentage =
      !isEmpty(triggerPrice) &&
      ((order?.average_price - triggerPrice) / order?.average_price) * 100;
  }

  const handleSetStopLoss = async () => {
    try {
      setSubmitLoader(true);

      const expiry = moment(order.expiry_date, "DD-MMM-YYYY").format(
        "YYYY-MM-DD"
      );
      const payload = {
        userId: user?._id,
        broker: brokerage,
        stock_code: order?.stock_code,
        sl_quantity: parseFloat(quantity),
        sl_trigger_price: parseFloat(triggerPrice),
        sl_limit_price: parseFloat(limitPrice),
        action: order?.action?.toUpperCase(),
        strike: order?.strike_price,
        call: order?.right,
        underlying: order?.underlying,
      };

      if (brokerage === "icici") {
        payload["expiry"] = expiry;
      } else if (brokerage === "zerodha") {
        payload["tradingsymbol"] = order?.tradingsymbol;
      }

      const response = await postStopLoss(payload);
      if (response.success) {
        setSubmitLoader(false);
        onHide(setQuantity, setTriggerPrice, setLimitPrice);
        fetchPositionsData();
      } else {
        setSubmitLoader(false);
        dispatch(
          openToast({
            message: response?.message || "Some error occurred",
            type: "error",
          })
        );
      }
    } catch (error) {
      console.error("error: ", error);
      dispatch(
        openToast({
          message: "Something went wrong",
          type: "error",
        })
      );
    }
  };

  return (
    <DraggableModal
      show={show}
      onHide={() => onHide(setQuantity, setTriggerPrice, setLimitPrice)}
      title={`${user?.name} - ${
        brokerage === "icici"
          ? "ICICI DIRECT"
          : brokerage.charAt(0).toUpperCase() + brokerage.slice(1)
      }`}
    >
      <Container>
        <Row className="mb-3">
          <Col xs={5}>Contract:</Col>
          <Col style={{ padding: "0px" }} xs={7}>
            {order?.contract}
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={5}>Position:</Col>
          <Col style={{ padding: "0px" }} xs={7}>
            {order?.action} {"->"} {order?.action === "Sell" ? "Buy" : "Sell"}
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={5}>Avg Price:</Col>
          <Col style={{ padding: "0px" }} xs={7}>
            {order?.average_price}
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={5}>LTP:</Col>
          <Col style={{ padding: "0px" }} xs={7}>
            {order?.ltp}
          </Col>
        </Row>

        <Row className="mb-3">
          <Col xs={5}>Quantity:</Col>
          <Col style={{ padding: "0px" }} xs={7}>
            {order?.quantity}
          </Col>
        </Row>

        <Row className="flex items-center mb-3">
          <Col xs={5}>SL Quantity:</Col>
          <Col style={{ padding: "0px" }} xs={7}>
            <div className="w-7/12">
              <Form.Control
                size="sm"
                type="number"
                className="all-normal-text"
                name="stoplossquantity"
                value={quantity}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
          </Col>
        </Row>

        <Row className="flex items-center mb-3">
          <Col xs={5}>SL Trigger Price:</Col>
          <Col style={{ padding: "0px" }} xs={7}>
            <div className="flex items-center gap-2">
              <div className="w-7/12">
                <Form.Control
                  size="sm"
                  type="number"
                  name="stoplosstriggerprice"
                  className="all-normal-text"
                  value={triggerPrice}
                  onChange={(e) => setTriggerPrice(e.target.value)}
                  onBlur={handleTriggerBlur}
                />
              </div>
              <div
                className={`${
                  parseFloat(pricePrecentage) < 0
                    ? "loss-color"
                    : "profit-color"
                }
                all-normal-text p-2
                    `}
              >{`${
                isNaN(parseFloat(pricePrecentage).toFixed(2)) ||
                isEmpty(parseFloat(pricePrecentage).toFixed(2))
                  ? 0
                  : parseFloat(pricePrecentage).toFixed(2)
              }%`}</div>
            </div>
          </Col>
        </Row>

        <Row className="flex items-center">
          <Col xs={5}>SL Limit Price:</Col>
          <Col style={{ padding: "0px" }} xs={7}>
            <div className="w-7/12">
              <Form.Control
                size="sm"
                type="number"
                name="stoplosslimitprice"
                value={limitPrice}
                onChange={(e) => setLimitPrice(e.target.value)}
                onBlur={handleLimitBlur}
              />
            </div>
          </Col>
        </Row>
      </Container>

      <div style={{ justifyContent: "center" }} className="flex">
        <div className="flex gap-2 justify-center mt-3">
          <div>
            <Button
              className="button-style"
              variant="primary"
              onClick={handleSetStopLoss}
            >
              {submitLoader ? <Spinner size="sm" /> : "Set Stop Loss"}
            </Button>
          </div>
          <div>
            <Button
              className="button-style"
              variant="secondary"
              onClick={() =>
                onHide(setQuantity, setTriggerPrice, setLimitPrice)
              }
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </DraggableModal>
  );
};

export default StopLoss;
